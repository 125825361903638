import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LoginMainContent.module.css';
import '@fortawesome/fontawesome-free/css/all.min.css';  // Importa FontAwesome
import { AuthContext } from '../../AuthContext/AuthContext';

const LoginMainContent = () => {
  const [usuario, setUsuario] = useState('');
  const [contrasena, setContrasena] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    setIsButtonDisabled(true); // Desactiva el botón
    e.preventDefault();
    setError(null); // Reset error state

    try {
      await login(usuario, contrasena);
      navigate('/home');
    } catch (error) {
      setError(error); // Set the error message
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false); // Reactiva el botón después de 3.5 segundos
      }, 3500);
    }
  };

  return (
    <main className={styles.loginMainContent}>
      <div className={styles.overlay}>
        <div className={styles.loginContainer}>
          <form className={styles.loginForm} onSubmit={handleLogin}>
            <div className={styles.loginIcon}></div>
            <input
              type="text"
              placeholder="Usuario"
              className={styles.loginInput}
              value={usuario}
              onChange={(e) => setUsuario(e.target.value)}
            />
            <div className={styles.passwordContainer}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Contraseña"
                className={styles.loginInput}
                value={contrasena}
                onChange={(e) => setContrasena(e.target.value)}
              />
              <button
                type="button"
                className={styles.passwordToggle}
                onClick={togglePasswordVisibility}
                disabled={isButtonDisabled}
              >
                <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'} ${styles.eyeIcon}`}></i>
              </button>
            </div>
            {error && <div className={styles.errorMessage}>{error}</div>}
            <button type="submit" className={styles.loginButton}>Iniciar Sesión</button>
            <a href="/register" className={styles.createAccountLink}>¿No tienes una cuenta? Crea una cuenta.</a>
            <a href="/mail-contraseña" className={styles.createAccountLink}>¿Olvidaste tu contraseña?</a>
            <a href="/mail-usuario" className={styles.createAccountLink}>¿Olvidaste tu usuario?</a>
          </form>
        </div>
      </div>
    </main>
  );
};

export default LoginMainContent;
