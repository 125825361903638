import React, { useState } from 'react';
import styles from './MailParaUsuarioMainContent.module.css';
import AsterImage from './Images/ContrasenaAster.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MailUserMainContent = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/forgot-user', { email });
      // Siempre muestra el mensaje de éxito, sin importar la respuesta
      setMessage('Correo de recuperación enviado exitosamente.');
      // Redirige a la página de inicio de sesión después de unos segundos
      setTimeout(() => {
        navigate('/login');
      }, 4000);
    } catch (error) {
      // Incluso si ocurre un error, muestra el mensaje de éxito
      setMessage('Correo de recuperación enviado exitosamente.');
      console.log('Error:', error.response ? error.response.data : error.message); // Debugging line
      // Redirige a la página de inicio de sesión después de unos segundos
      setTimeout(() => {
        navigate('/login');
      }, 4000);
    }
  };

  return (
    <div className={styles.mainContent}>
      <div className={styles.backgroundImage}></div>
      <div className={styles.passwordResetBox}>
        <div className={styles.passwordResetTitle}>Recuperar usuario</div>
        <div className={styles.asterContainer}>
          <img src={AsterImage} alt="Aster" className={styles.asterImage} />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.passwordInputContainer}>
            <input 
              type="text"
              placeholder="Ingresa tu e-mail" 
              value={email} 
              onChange={handleEmailChange} 
              className={styles.passwordInput}
            />
          </div>
          <button type="submit" className={styles.submitButton}>Enviar correo</button>
        {message && <div className={styles.message}>{message}</div>}
        <div href="/login" className={styles.iniciarSesionBoton}>¿Ya tienes cuenta? Inicia sesión aquí</div>
        </form>
      </div>
    </div>
  );
};

export default MailUserMainContent;
