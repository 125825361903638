import React from 'react';
import Header from '../../Verify/Header/VerifyHeader'
import MailParaUsuarioMainContent from './MainContent/MailParaUsuarioMainContent';
import Footer from '../../LandingPage/Footer/Footer'

const MailParaUsuario = () => {
  return (
    <div>
        <Header/>
        <MailParaUsuarioMainContent />
        <Footer/>
    </div>
  );
};

export default MailParaUsuario;
