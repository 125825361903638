import React, { Component } from 'react';
import styles from './Overlay.module.css';
import Seccion1 from './S1/S1';
import Seccion2 from './S2/S2';
import { resetChat } from '../Seccion1/S1';

class Overlay extends Component {
    constructor(props) {
        super(props);

        let initialUnidades = Array.isArray(props.unidades) ? props.unidades : [];

        const lastUnidadId = initialUnidades.length > 0 ? Math.max(...initialUnidades.map(u => u.id)) : 0;

        this.nuevaUnidadPISA = {
            id: lastUnidadId + 1, // Por ejemplo, si lastUnidadId es 99, id será 100
            titulo: 'Evaluación PISA',
            activo: false,
            temas: [
                { id: (lastUnidadId + 1) * 100 + 1, nombre: 'Matemáticas', activo: false, letra: 'm' },
                { id: (lastUnidadId + 1) * 100 + 2, nombre: 'Ciencia', activo: false, letra: 'c' },
                { id: (lastUnidadId + 1) * 100 + 3, nombre: 'Comprensión lectora', activo: false, letra: 'l' },
            ],
        };

        initialUnidades = [
            ...initialUnidades,
            this.nuevaUnidadPISA,
        ];

        this.state = {
            isCheckboxChecked: true,
            isVisible: true,
            totalPreguntas: '',
            unidades: initialUnidades,
            hoverIndex: null,
            responseData: null,
            isRedirecting: false,
            queriesPISA: {} // Inicializa queriesPISA con valores por defecto
        };

        this.handleAceptar = this.handleAceptar.bind(this);
        this.toggleUnidadActivo = this.toggleUnidadActivo.bind(this);
        this.toggleTemaActivo = this.toggleTemaActivo.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handlePreguntasChange = this.handlePreguntasChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.extraerInformacion = this.extraerInformacion.bind(this);
        this.temasActivos = this.temasActivos.bind(this);
        this.puedeAceptar = this.puedeAceptar.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
    }

    handleMouseEnter() {
        this.setState({ hoverIndex: true });
    }

    handleMouseLeave() {
        this.setState({ hoverIndex: false });
    }

    async verifyToken() {
        const token = localStorage.getItem('token');
        if (!token) {
          return false;
        }
    
        try {
          const response = await fetch('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/auth/me', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.ok) {
            return true;
          } else {
            localStorage.removeItem('token');
            return false;
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          localStorage.removeItem('token');
          return false;
        }
      }


      
      async handleAceptar() {
        const { backendUrlGeneracion, identificador, rama, libro } = this.props;
        const tokenIsValid = await this.verifyToken();
    
        if (!tokenIsValid) {
            this.setState({ isRedirecting: true });
            alert("Token caducado. Vuelve a iniciar sesión.");
            return;
        }
    
        // Ejecuta extraerInformacion para actualizar queries y queriesPISA
        const queries = this.extraerInformacion();
    
        // Utiliza una función de actualización del estado para garantizar que se tiene el valor más reciente
        this.setState(
            (prevState) => ({
                queriesPISA: Object.fromEntries(
                    Object.entries(prevState.queriesPISA).filter(([_, value]) => value > 0)
                ),
            }),
            () => {
                // Una vez que el estado se haya actualizado, utiliza el valor actualizado para enviar al backend
                const { queriesPISA } = this.state;
                // Cierra el overlay al completar la operación
                this.handleClose();
                
                const dataToSend = {
                    identificador,
                    libro,
                    queries,
                    queriesPISA,
                    rama,
                };
    
                console.log("Datos enviados al backend:", dataToSend); // Verificar el objeto enviado
    
                fetch(backendUrlGeneracion, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Psico-API-Key": "94705224-bhvg-4745-mac7-f15c455858f4",
                    },
                    body: JSON.stringify(dataToSend),
                })
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            throw new Error("Error en la respuesta del servidor");
                        }
                    })
                    .then((data) => {
                        this.setState({ responseData: data.response });
                        this.props.setResponseData(data.response);
    
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            }
        );
    }    
       
    

    extraerInformacion() {
        const { isCheckboxChecked, totalPreguntas, unidades } = this.state;
        const resultado = {}; // Esto irá a queries (temas generales)
        const queriesPISA = {}; // Inicializar vacío para incluir solo las claves necesarias
    
        if (isCheckboxChecked && totalPreguntas) {
            const totalPreguntasNum = parseInt(totalPreguntas, 10);
            let temasActivos = [];
    
            // Obtener los temas activos de todas las unidades
            unidades.forEach((unidad) => {
                unidad.temas.forEach((tema) => {
                    if (tema.activo) {
                        temasActivos.push({ ...tema, unidadId: unidad.id });
                    }
                });
            });
    
            // Distribuir proporcionalmente las preguntas entre los temas activos
            const preguntasPorTema = Math.floor(totalPreguntasNum / temasActivos.length);
            const residuo = totalPreguntasNum % temasActivos.length;
    
            temasActivos.forEach((tema, index) => {
                const preguntasAsignadas = preguntasPorTema + (index < residuo ? 1 : 0);
                if (tema.unidadId === this.nuevaUnidadPISA.id) {
                    // Si el tema pertenece a la unidad PISA, asignarlo a queriesPISA
                    if (preguntasAsignadas > 0) {
                        queriesPISA[tema.letra] = preguntasAsignadas;
                    }
                } else {
                    // Si no es PISA, asignarlo a queries con su clave
                    const temaIdFormat = tema.id % 100;
                    const clave = `${tema.unidadId}.${temaIdFormat.toString().padStart(2, '0')}`;
                    resultado[clave] = preguntasAsignadas;
                }
            });
        } else {
            // Si las preguntas se asignan individualmente por tema
            unidades.forEach((unidad) => {
                unidad.temas.forEach((tema) => {
                    if (tema.npreguntas && tema.npreguntas > 0) {
                        const numeroPreguntas = parseInt(tema.npreguntas, 10) || 0;
    
                        if (unidad.id === this.nuevaUnidadPISA.id) {
                            // Si pertenece a PISA, asignarlo a queriesPISA
                            queriesPISA[tema.letra] = numeroPreguntas;
                        } else {
                            // Si no es PISA, asignarlo a queries con su clave
                            const temaIdFormat = tema.id % 100;
                            const clave = `${unidad.id}.${temaIdFormat.toString().padStart(2, '0')}`;
                            resultado[clave] = numeroPreguntas;
                        }
                    }
                });
            });
        }
    
        console.log("Extraer Información - Queries PISA:", queriesPISA); // Debug para verificar asignación
        this.setState({ queriesPISA });
        return resultado;
    }     
    

    toggleUnidadActivo(index) {
        const nuevasUnidades = this.state.unidades.map((unidad, i) =>
            ({ ...unidad, activo: i === index ? !unidad.activo : unidad.activo })
        );
        this.setState({ unidades: nuevasUnidades });
    }

    toggleTemaActivo(idUnidad, idTema) {
        const nuevasUnidades = this.state.unidades.map(unidad => {
            if (unidad.id === idUnidad) {
                const nuevosTemas = unidad.temas.map(tema =>
                    ({ ...tema, activo: tema.id === idTema ? !tema.activo : tema.activo })
                );
                return { ...unidad, temas: nuevosTemas };
            }
            return unidad;
        });
    
        // Actualiza queriesPISA dinámicamente
        const queriesPISA = { ...this.state.queriesPISA };
        const temaPISA = this.nuevaUnidadPISA.temas.find(t => t.id === idTema);
    
        if (temaPISA) {
            // Si es un tema PISA, alterna su inclusión en queriesPISA
            if (queriesPISA[temaPISA.letra]) {
                delete queriesPISA[temaPISA.letra]; // Elimina si ya no está activo
            } else {
                queriesPISA[temaPISA.letra] = 0; // Inicializa con 0
            }
        }
    
        this.setState({ unidades: nuevasUnidades, queriesPISA });
        console.log("Toggle Tema Activo - Queries PISA:", queriesPISA); // Debug para verificar
    }
    

    toggleCheckbox() {
        this.setState(prevState => {
            if (!prevState.isCheckboxChecked) {
                const nuevasUnidades = prevState.unidades.map(unidad => ({
                    ...unidad,
                    temas: unidad.temas.map(tema => ({ ...tema, npreguntas: '' }))
                }));
                return { isCheckboxChecked: !prevState.isCheckboxChecked, unidades: nuevasUnidades };
            }
            return { isCheckboxChecked: !prevState.isCheckboxChecked };
        });
    }

    handlePreguntasChange(idUnidad, idTema, value) {
        const nuevasUnidades = this.state.unidades.map(unidad => {
            if (unidad.id === idUnidad) {
                const nuevosTemas = unidad.temas.map(tema =>
                    tema.id === idTema ? { ...tema, npreguntas: value } : tema
                );
                return { ...unidad, temas: nuevosTemas };
            }
            return unidad;
        });
        // Actualiza queriesPISA dinámicamente si es un tema PISA
        const queriesPISA = { ...this.state.queriesPISA };
        const temaPISA = this.nuevaUnidadPISA.temas.find(t => t.id === idTema);

        if (temaPISA) {
            // Si es un tema PISA, actualiza su valor en queriesPISA
            queriesPISA[temaPISA.letra] = parseInt(value, 10) || 0;
        }

        this.setState({ unidades: nuevasUnidades, queriesPISA });
    }


    handleClose() {
        this.setState({ isVisible: false });
        this.props.onAccept();
    }

    temasActivos() {
        return this.state.unidades.some(unidad => unidad.temas.some(tema => tema.activo));
    }

    puedeAceptar() {
        const { isCheckboxChecked, unidades, totalPreguntas } = this.state;

        const temasSeleccionados = unidades.reduce((acc, unidad) => 
            acc + unidad.temas.filter(tema => tema.activo).length, 0);

        const preguntasTotalesValidas = isCheckboxChecked && totalPreguntas !== '' && !isNaN(Number(totalPreguntas)) && Number(totalPreguntas) > 0 && temasSeleccionados > 0 && Number(totalPreguntas) >= temasSeleccionados;

        const preguntasIndividualesValidas = !isCheckboxChecked && unidades.some(unidad =>
            unidad.temas.some(tema =>
                tema.npreguntas !== '' && !isNaN(Number(tema.npreguntas)) && Number(tema.npreguntas) > 0
            )
        );

        const temasActivos = unidades.some(unidad =>
            unidad.temas.some(tema => tema.activo)
        );

        return (preguntasTotalesValidas && temasActivos) || preguntasIndividualesValidas;
    }

    render() {
        const { isCheckboxChecked, totalPreguntas, unidades, isVisible, hoverIndex, responseData, isRedirecting } = this.state;

        if (isRedirecting) {
            window.location.href = '/login';
            return;
        }

        if (!isVisible) return null;

        const botonAceptarStyle = {
            backgroundColor: hoverIndex ? this.props.botonAceptarColorHover : this.props.botonAceptarColor,
            '--boton-aceptar-color': this.props.botonAceptarColorHover
        };

        return (
            <div className={styles.overlay} style={{ backgroundColor: this.props.modalColor}}>
                <div className={styles.modal} style={{ backgroundColor: this.props.modalColor }}>
                    <form>
                        <Seccion1
                            isCheckboxChecked={isCheckboxChecked}
                            setIsCheckboxChecked={(checked) => this.setState({ isCheckboxChecked: checked })}
                            toggleCheckbox={this.toggleCheckbox}
                            totalPreguntas={totalPreguntas}
                            setTotalPreguntas={(value) => this.setState({ totalPreguntas: value })}
                            unidades={unidades}
                            onToggleUnidad={this.toggleUnidadActivo}
                            optionGroupColor={this.props.generalBackgroundColor}
                            checkmarkColor={this.props.checkmarkColor}
                            checkmarkBorderColor={this.props.checkmarkBorderColor}
                            checkmarkHoverColor={this.props.checkmarkHoverColor}
                            checkmarkCheckedColor={this.props.checkmarkCheckedColor}
                            scrollbarTrackColor={this.props.scrollbarTrackColor}
                            scrollbarThumbColor={this.props.scrollbarThumbColor}
                            scrollbarThumbHoverColor={this.props.scrollbarThumbHoverColor}
                            responseData={responseData} // Pasar responseData a Seccion1
                        />
                        <Seccion2
                            isCheckboxChecked={isCheckboxChecked}
                            toggleTemaActivo={this.toggleTemaActivo}
                            unidades={unidades}
                            handlePreguntasChange={this.handlePreguntasChange}
                            containerColor={this.props.generalBackgroundColor}
                            checkmarkColor={this.props.checkmarkColor}
                            checkmarkBorderColor={this.props.checkmarkBorderColor}
                            checkmarkHoverColor={this.props.checkmarkHoverColor}
                            checkmarkCheckedColor={this.props.checkmarkCheckedColor}
                            scrollbarTrackColor={this.props.scrollbarTrackColor}
                            scrollbarThumbColor={this.props.scrollbarThumbColor}
                            scrollbarThumbHoverColor={this.props.scrollbarThumbHoverColor}
                        />
                        <button
                            type="button"
                            onClick={this.handleAceptar}
                            disabled={!this.puedeAceptar()}
                            className={`${styles.acceptButton} ${!this.puedeAceptar() ? styles.buttonDisabled : ''}`}
                            style={botonAceptarStyle}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                        >
                            ACEPTAR
                        </button>
                    </form>
                </div>
            </div>
        );
    }
}

export default Overlay;
