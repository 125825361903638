import React, { Component } from 'react';
import styles from './S1.module.css';
import Imagen1 from './images/aster.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { BlockMath } from 'react-katex';
import 'katex/dist/katex.min.css';

let messagesBack = [];
let evaluacionBack = "";

const resetChat = () => {
    messagesBack = [[]];
};

function downloadFile(base64Data, fileName, mimeType) {
    const binaryString = window.atob(base64Data);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([bytes], { type: mimeType });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    window.URL.revokeObjectURL(link.href);
}

class Seccion1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messages: [{ role: "system", content: "Generando la evaluación. Por favor, no escribas hasta obtener la respuesta." }],
            inputMessage: "",
            isSending: false,
            isTyping: false,
            evaDocFile: null,
            evaPdfFile: null,
            isChatActive: true,
            hoverIndex: null, // Estado para manejar el hover del botón enviar
            docxHoverIndex: null, // Estado para manejar el hover del botón docx
            isRedirecting: false
        };

        // Enlazar métodos al contexto this
        this.sendMessage = this.sendMessage.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleEvaDocClick = this.handleEvaDocClick.bind(this);
        this.handleEvaPdfClick = this.handleEvaPdfClick.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleDocxMouseEnter = this.handleDocxMouseEnter.bind(this);
        this.handleDocxMouseLeave = this.handleDocxMouseLeave.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.responseData !== this.props.responseData && this.props.responseData) {
            this.setState(prevState => ({
                messages: [...prevState.messages, { role: "system", content: `${this.props.responseData}\n¿Deseas algún cambio?` }]
            }));
            messagesBack = [{ role: "system", content: "prompt" }, { role: "assistant", content: `${this.props.responseData}\n¿Deseas algún cambio?` }];
            evaluacionBack = this.props.responseData;
        }

        if (prevState.messages.length !== this.state.messages.length) {
            this.scrollToBottom();
        }
    }

    scrollToBottom() {
        const escapedClassName = styles.chatmessages.replace(/\+/g, '\\+'); // Escapar el "+"
        const chatMessages = document.querySelector(`.${escapedClassName}`);
        if (chatMessages) {
            chatMessages.scrollTop = chatMessages.scrollHeight;
        }
    }
    

    async verifyToken() {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }
        
        try {
            const response = await fetch('https://bases-datos-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/auth/me', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                return true;
            } else {
                localStorage.removeItem('token');
                return false;
            }
        } catch (error) {
            console.error('Error verifying token:', error);
            localStorage.removeItem('token');
            return false;
        }
    }
  

    async sendMessage() {
        const { backendUrlCorrecion, identificador, rama, libro } = this.props;
        const { inputMessage } = this.state;
        const trimmedMessage = inputMessage.trim();
        if (!trimmedMessage) return;
    
        const tokenIsValid = await this.verifyToken();
        if (!tokenIsValid) {
            this.setState({ isRedirecting: true });
            alert("Token caducado. Vuelve a iniciar sesión.");
            return;
        }
    
        const newMessage = { role: "user", content: trimmedMessage };
        messagesBack.push(newMessage);
    
        this.setState(prevState => ({
            messages: [...prevState.messages, newMessage],
            inputMessage: '',
            isSending: true,
            isTyping: true,
            isChatActive: false
        }), this.scrollToBottom);
    
        let dataToSend = {
            messages: messagesBack,
            identificador,
            libro,
            evaluacion: evaluacionBack,
            rama
        };
    
        try {
            // Primera solicitud para iniciar el proceso de corrección
            const initResponse = await fetch(backendUrlCorrecion, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Psico-API-Key': '94705224-bhvg-4745-mac7-f15c455858f4'
                },
                body: JSON.stringify(dataToSend)
            });
    
            const initData = await initResponse.json();
    
            // Si el proceso se ha iniciado correctamente, comenzamos a verificar su estado
            if (initData.procesoIniciado === true) {
                await this.checkEvaluationStatus(identificador);
            }
        } catch (error) {
            console.error('Error en la solicitud inicial:', error);
            this.setState({
                isSending: false,
                isTyping: false,
                isChatActive: true
            });
        }
    }
    
    // Función separada para chequear el estado del proceso de evaluación
    async checkEvaluationStatus(identificador) {
        let doc = null;
        const dataToSend = { identificador };
    
        try {
            // Mientras no se haya recibido el documento evaluado
            while (doc === null) {
                console.log("Solicitando estado de la evaluación...");
    
                const evalResponse = await fetch("https://api-didactica-profesores.d5akl8suhondc.us-east-1.cs.amazonlightsail.com/getEvaluacion", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Psico-API-Key': '94705224-bhvg-4745-mac7-f15c455858f4'
                    },
                    body: JSON.stringify(dataToSend)
                });
    
                const evalData = await evalResponse.json();
    
                if (evalData.evaDoc) {
                    // Cuando el documento esté disponible, actualizamos el estado
                    this.setState(prevState => ({
                        isSending: false,
                        isTyping: false,
                        isChatActive: true,
                        messages: evalData.response && !prevState.messages.find(m => m.content === evalData.response)
                            ? [...prevState.messages, { role: "assistant", content: evalData.response }]
                            : prevState.messages,
                        evaDocFile: evalData.evaDoc,
                        evaPdfFile: evalData.evaPdf || null
                    }), this.scrollToBottom);
    
                    messagesBack = evalData.messages;
                    evaluacionBack = evalData.evaluacion;
                    doc = evalData.evaDoc; // Salir del bucle cuando `evaDoc` no sea null
                } else {
                    // Si el documento no está listo, esperamos 10 segundos antes de intentar de nuevo
                    await new Promise(resolve => setTimeout(resolve, 10000));
                }
            }
        } catch (error) {
            console.error('Error en la solicitud de estado:', error);
            this.setState({
                isSending: false,
                isTyping: false,
                isChatActive: true
            });
        }
    }
    
    handleInputChange(e) {
        this.setState({ inputMessage: e.target.value });
    }

    handleKeyDown(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            this.sendMessage();
        }
    }

    handleEvaDocClick() {
        const { evaDocFile } = this.state;
        if (evaDocFile) {
            try {
                downloadFile(evaDocFile, 'Evaluacion.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document');
            } catch (error) {
                console.error('Error al procesar el archivo:', error);
                alert('Error al procesar el archivo para descargar.');
            }
        } else {
            alert("No hay un documento disponible para descargar en este momento. Por favor, intenta de nuevo más tarde.");
        }
    }

    handleEvaPdfClick() {
        const { evaPdfFile } = this.state;
        if (evaPdfFile) {
            try {
                downloadFile(evaPdfFile, 'Evaluacion.pdf', 'application/pdf');
            } catch (error) {
                console.error('Error al procesar el archivo:', error);
                alert('Error al procesar el archivo para descargar.');
            }
        } else {
            alert("No hay un documento disponible para descargar en este momento. Por favor, intenta de nuevo más tarde.");
        }
    }

    handleMouseEnter() {
        this.setState({ hoverIndex: true });
    }

    handleMouseLeave() {
        this.setState({ hoverIndex: false });
    }

    handleDocxMouseEnter() {
        this.setState({ docxHoverIndex: true });
    }

    handleDocxMouseLeave() {
        this.setState({ docxHoverIndex: false });
    }

    renderMessageContent(message) {
        const parts = message.split(/(\$[\s\S]*?\$)/g);
        return parts.map((part, index) => {
            if (part.startsWith('$') && part.endsWith('$')) {
                return <BlockMath key={index}>{part.slice(1, -1)}</BlockMath>;
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    }

    render() {
        const { messages, inputMessage, isSending, isTyping, isChatActive, hoverIndex, docxHoverIndex } = this.state;
        const { chatContainerColor, chatHeaderColor, chatFormColor, userBubbleColor, assistantBubbleColor, headerTextColor, sendButtonColor, sendButtonHoverColor, fondoDelChat, textAreaBorder, btndocxColor, btndocxColorHover } = this.props;

        const chatContainerStyle = { backgroundColor: chatContainerColor };
        const chatHeaderStyle = { backgroundColor: chatHeaderColor };
        const chatFormStyle = { backgroundColor: chatFormColor };
        const userBubbleStyle = { backgroundColor: userBubbleColor };
        const assistantBubbleStyle = { backgroundColor: assistantBubbleColor };
        const headerTextStyle = { color: headerTextColor };
        const sendButtonStyle = { backgroundColor: sendButtonColor };
        const sendButtonHoverStyle = hoverIndex ? { backgroundColor: sendButtonHoverColor } : { backgroundColor: sendButtonColor };
        const fondoDelChatStyle = { backgroundColor: fondoDelChat };
        const textAreaStyle = { borderColor: textAreaBorder };
        const btndocxStyle = docxHoverIndex ? { backgroundColor: btndocxColorHover } : { backgroundColor: btndocxColor };
        const btndocxHoverStyle = docxHoverIndex ? { backgroundColor: btndocxColorHover } : { backgroundColor: btndocxColor };

        if (this.state.isRedirecting) {
            window.location.href = '/login';
            return;
        }

        return (
            <div className={styles.container}>
                <div className={styles.rightRectangle}>
                    <div className="custom-section">
                        <div className={styles.chatcontainer} style={chatContainerStyle}>
                            <div className={styles.chatheader} style={chatHeaderStyle}>
                                <div className={styles.headercontent}>
                                    <div className={styles.profilepicture}>
                                        <img src={Imagen1} alt="Profile" />
                                    </div>
                                    <h2 style={headerTextStyle}>Aster asistente docente</h2>
                                    <div className={styles.headerButtons}>
                                        <button 
                                            className={`${styles.btndocx} ${!this.state.evaDocFile ? styles.buttonDisabled : ''}`} 
                                            onClick={this.handleEvaDocClick} 
                                            disabled={!this.state.evaDocFile} 
                                            style={btndocxStyle} 
                                            onMouseEnter={this.handleDocxMouseEnter} 
                                            onMouseLeave={this.handleDocxMouseLeave}>
                                            Descargar docx
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.chatmessages} style={fondoDelChatStyle}>
                                {messages.map((message, index) => (
                                    <div key={index} className={`${styles.messagebubble} ${message.role === "user" ? styles.user : styles.assistant}`}
                                         style={message.role === "user" ? { backgroundColor: userBubbleColor } : { backgroundColor: assistantBubbleColor }}>
                                        {message.image ? (
                                            <img src={message.image} alt="Fetched from backend" className={styles.scaledImage} />
                                        ) : (
                                            this.renderMessageContent(message.content)
                                        )}
                                    </div>
                                ))}
                                {isTyping && <div className={`${styles.messagebubble} ${styles.typing}`}>Escribiendo...</div>}
                            </div>

                            <form className={styles.chatform} style={chatFormStyle} onSubmit={(e) => {
                                e.preventDefault();
                                this.sendMessage();
                            }}>
                                <textarea className={styles.formcontrol}
                                    value={inputMessage}
                                    onChange={this.handleInputChange}
                                    placeholder="Escríbeme tu mensaje"
                                    required
                                    rows="1"
                                    onKeyDown={this.handleKeyDown}
                                    disabled={!isChatActive}
                                    style={textAreaStyle}>
                                </textarea>
                                <button type="submit" className={styles.btnsend} disabled={!isChatActive || isSending} style={sendButtonHoverStyle} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                                    <FontAwesomeIcon icon={faPaperPlane} style={{ color: 'white' }} />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Seccion1;
export { resetChat };
